<template>
  <div class="login">
    <div class="content">
      <div class="form">
        <div style="display: inline-block;width: 400px  ;margin-right: 40px; ">
          <el-image style="width:700px ;vertical-align: bottom; border-radius: 0 5px 5px  0;"
            :src="require('./../assets/new_icon/login.jpg')"></el-image>
        </div>
        <div style="display: inline-block;
    width: 300px;
    position: absolute;
    right: 100px;
    top: 166px;
    ">
          <el-form class="demo-form-inline">
            <div class="title">
              <h3>河南省高考志愿填报</h3>
              <br>
              <h3>导师平台登录</h3>

            </div>
            <p>
              <el-input v-model="mobile" class="login-input" placeholder="账号" suffix-icon="el-icon-user" />
            </p>
            <p style="position: relative">
              <el-input v-model="password" show-password class="login-input" placeholder="请输入密码"
                @keyup.enter.native="login" />
            </p>
            <div v-if="login_btn" @click="login" class="login_btn_ture">登录</div>
            <div v-else class="login_btn">登录</div>
          </el-form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Login",
  computed: {
    changeData() {
      const { mobile, password } = this;
      return {
        mobile,
        password,
      };
    },
  },
  watch: {
    changeData() {
      if (this.mobile && this.password) {
        this.login_btn = true;
      } else {
        this.login_btn = false;
      }
    },
  },
  data() {
    return {
      login_btn: false,
      mobile: "",
      password: "",
    };
  },
  methods: {
    ...mapMutations(["setInfo"]),
    // 登录
    login() {
      if (!this.VerifyPhone()) {
        return;
      }
      this.$fecth
        .post("login/login", {
          mobile: this.mobile,
          password: this.password,
        })
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 200) {
            localStorage.setItem("TOKEN", data.token);
            localStorage.setItem("ExpertInfo", JSON.stringify(data));
            this.$notify({
              showClose: true,
              message: "恭喜你，登录成功！",
              type: "success",
            });

            //store 添加
            this.setInfo(data);

            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          } else {
            this.$notify({
              showClose: true,
              message: msg,
              type: "error",
            });
          }
        });
    },
    VerifyPhone() {
      let reg = /^1[0-9]{10}$/;
      if (
        this.mobile == "" ||
        this.mobile.length <= 10 ||
        !reg.test(this.mobile)
      ) {
        this.$message("手机号格式错误");
        return;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="less">
.login-input {
  .el-input__inner {
    border: rgb(174, 182, 174) 1px solid;
  }
}
</style>

<style lang="less" scoped>
.login {
  min-height: 970px;
  overflow: hidden;

  .content {
    width: 1200px;
    margin: 0 auto;
  }

  .form {
    position: relative;
    width: 1200px;
    margin: 140px auto 0;
    background: white;
    border-radius: 7px;
    border-radius: 5px 0 0 5px;
    overflow: hidden;

    .title {
      h3 {
        line-height: 30px;
        display: inline-block;
      }

      margin-bottom: 20px;
    }

    p {
      margin: 0 auto 20px;
    }

    .login_btn {
      background: #cecece;
      line-height: 40px;
      color: #757575;
      text-align: center;
      letter-spacing: 3px;
      font-size: 13px;
      border-radius: 4px;
    }

    .login_btn_ture {
      background: #12a0f3;
      line-height: 40px;
      color: white;
      text-align: center;
      letter-spacing: 3px;
      font-size: 13px;
      border-radius: 4px;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      font-size: 12px;
      color: #cecece;

      a {
        color: #cecece;
      }
    }
  }
}
</style>